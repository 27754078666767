import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProjectList from '../components/Projects/ProjectList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, categories, categoryFilter, site, yoast = [], wordpressWpSettings } = data
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug, pathPrefix } = pageContext
    const title = `${category} Category`

    return (
      <Layout className={`page-wrap`}>
        <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} yoast={yoast} />
        <PageHeader headerTitle={title} headerBackgroundImage="" location={location} />
        <ProjectList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categories={categories.edges}
          siteMetadata={wordpressWpSettings}
          pathPrefix={`/project-category/${slug}/`}
        />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectCategoryPage($category_id: [Int], $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    postsOnPage: allWordpressWpProjects(
      filter: {
        project_category: {in: $category_id}
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          acf {
            title
            featureImage {
              source_url
            }
            categoryPageDescription
            categoryHeaderImage {
              source_url
            }
          }
          wordpress_id
          wordpress_parent
        }
      }
    }
  }
`
